// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__content--OzIaq {\n  padding: 24px;\n  width: 100%;\n  box-sizing: border-box;\n  border-radius: 10px;\n  border: 1px solid var(--gray);\n  margin-top: 24px;\n}\n.s-module__content--OzIaq .ant-descriptions-row > th {\n  padding-bottom: 4px;\n}\n.s-module__content--OzIaq .ant-descriptions-row > td {\n  padding-bottom: 0;\n}\n.s-module__remove--SIjsz {\n  color: #C45407;\n}\n.s-module__remove--SIjsz:hover {\n  color: #C45427 !important;\n}\n.s-module__carouselImg--zE4ia {\n  max-width: 214px;\n  max-height: 214px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/operation/HelloKlarityPageManagement/components/CarouselsCard/s.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,sBAAA;EACA,mBAAA;EACA,6BAAA;EACA,gBAAA;AACF;AAPA;EAUM,mBAAA;AAAN;AAVA;EAcM,iBAAA;AADN;AAMA;EACE,cAAA;AAJF;AAME;EACE,yBAAA;AAJJ;AAQA;EACE,gBAAA;EACA,iBAAA;AANF","sourcesContent":[".content {\n  padding: 24px;\n  width: 100%;\n  box-sizing: border-box;\n  border-radius: 10px;\n  border: 1px solid var(--gray);\n  margin-top: 24px;\n\n  :global {\n    .ant-descriptions-row>th {\n      padding-bottom: 4px;\n    }\n\n    .ant-descriptions-row>td {\n      padding-bottom: 0;\n    }\n  }\n}\n\n.remove {\n  color: #C45407;\n\n  &:hover {\n    color: #C45427 !important;\n  }\n}\n\n.carouselImg {\n  max-width: 214px;\n  max-height: 214px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "s-module__content--OzIaq",
	"remove": "s-module__remove--SIjsz",
	"carouselImg": "s-module__carouselImg--zE4ia"
};
export default ___CSS_LOADER_EXPORT___;
