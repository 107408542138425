// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__conditionLogo--EHt8C {\n  width: 98px;\n  height: 98px;\n  border-radius: 24px;\n  background-color: #fff;\n}\n.s-module__wrapper--GI6fd {\n  min-height: 20vh;\n  margin-top: 20px;\n}\n.s-module__wrapper--GI6fd .ant-descriptions-row > th {\n  padding-bottom: 4px;\n}\n.s-module__wrapper--GI6fd .ant-descriptions-row > td {\n  padding-bottom: 0;\n}\n.s-module__panel--u1MVf {\n  border: 1px solid #DAD8D8;\n  padding: 24px 24px 32px 24px;\n  border-radius: 10px;\n  margin-bottom: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/operation/HelloKlarityPageManagement/components/ServiceManagement/s.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,sBAAA;AACF;AAEA;EACE,gBAAA;EACA,gBAAA;AAAF;AAFA;EAKM,mBAAA;AAAN;AALA;EAQM,iBAAA;AAAN;AAKA;EACE,yBAAA;EACA,4BAAA;EACA,mBAAA;EACA,mBAAA;AAHF","sourcesContent":[".conditionLogo {\n  width: 98px;\n  height: 98px;\n  border-radius: 24px;\n  background-color: #fff;\n}\n\n.wrapper {\n  min-height: 20vh;\n  margin-top: 20px;\n  :global {\n    .ant-descriptions-row >th {\n      padding-bottom: 4px;\n    }\n    .ant-descriptions-row >td {\n      padding-bottom: 0;\n    }\n  }\n}\n\n.panel {\n  border: 1px solid #DAD8D8;\n  padding: 24px 24px 32px 24px;\n  border-radius: 10px;\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"conditionLogo": "s-module__conditionLogo--EHt8C",
	"wrapper": "s-module__wrapper--GI6fd",
	"panel": "s-module__panel--u1MVf"
};
export default ___CSS_LOADER_EXPORT___;
