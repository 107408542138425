import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import s from './s.module.less';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { Button, Input, Checkbox, Space, Row, Col, Select, message, Spin, Tag } from 'antd';
import CommonTable from 'components/CommonTable';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import {
    getUniprofileList,
    updateProviderStatusByIds,
    getProviderViewPdf,
    exportProviderList,
    getContractVersionWithType,
} from 'api/operation';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import {
    GetOpsUniprofileInput,
    ProviderWithUniprofileStatus,
    ESourceName,
    ESource,
    EProduct,
} from 'types/operation';
import debounce from 'lodash/debounce';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import { CheckboxChangeEvent, CheckboxOptionType } from 'antd/lib/checkbox';
import ProfileStatusTag from 'components/ProfileAuditStatusTag';
import { PROVIDER_PAY_PLAN_TYPE } from 'constants/subscription';
import useSettings from 'hooks/useSettings';
import { EStatus, Meta } from 'types/common';
import Icons from './imgs';
import { TSubscriptionType } from 'types/subscription';
import capitalize from 'lodash/capitalize';
import { downloadFileWithBlob } from 'utils/file';
import useOpsPromotionCode from 'hooks/useOpsPromotionCode';

const allValue = 'All';

const Page = () => {
    const navigate = useNavigate();
    const [setting] = useSettings();
    const payStatusOptions = setting[EnumFields.PAYMENT_STATUS];
    const [pendingNameFilter, setPendingNameFilter] = useState('');
    const [pendingIdFilter, setPendingIdFilter] = useState('');
    const [pendingUidFilter, setPendingUidFilter] = useState('');
    const [pendingAssigneeFilter, setPendingAssigneeFilter] = useState('');

    const [auditStatusOptions, setAuditStatusOptions] = useState<CheckboxOptionType[]>([]);
    const [listingStatusOptions, setListingStatusOptions] = useState<CheckboxOptionType[]>([]);
    const signUpFromOptions = setting[EnumFields.PROVIDER_SOURCE];
    const [promotionCodeOptions] = useOpsPromotionCode();

    //plan
    const [planTypeIndeterminate, setPlanTypeIndeterminate] = useState(false);
    const [planTypeCheckAll, setPlanTypeCheckAll] = useState(false);
    const [planTypeList, setPlanTypeList] = useState<Array<string>>([]);
    const planTypeOptions = PROVIDER_PAY_PLAN_TYPE?.slice(0, 6)?.map((item) => ({ label: item.content, value: item.dictKey }));

    //payment
    const [payStatusIndeterminate, setPayStatusIndeterminate] = useState(false);
    const [payStatusCheckAll, setPayStatusCheckAll] = useState(false);
    const [payStatusList, setPayStatusList] = useState<Array<string>>([]);

    // ops 状态
    const [aduitStatusIndeterminate, setAduitStatusIndeterminate] = useState(false);
    const [aduitCheckAll, setAduitCheckAll] = useState(false);
    const [aduitList, setAduitList] = useState<Array<string>>([]);

    // 医生端状态
    const [listingStatusIndeterminate, setListingStatusIndeterminate] = useState(false);
    const [listingStatusCheckAll, setListingStatusCheckAll] = useState(false);
    const [listingStatusList, setListingStatusList] = useState<Array<string>>([]);

    // sign up from
    const [signUpFromIndeterminate, setSignUpFromIndeterminate] = useState(false);
    const [signUpFromCheckAll, setSignUpFromCheckAll] = useState(false);
    const [signUpFromList, setSignUpFromList] = useState<Array<string>>([]);

    //coupon
    const [promotionCodeIndeterminate, setPromotionCodeIndeterminate] = useState(false);
    const [promotionCodeCheckAll, setPromotionCodeCheckAll] = useState(false);
    const [promotionCodeList, setPromotionCodeList] = useState<Array<string>>([]);

    const [auditStatusData] = useGetSettingData(EnumFields.PROFILE_AUDIT_STATUS);
    const [listingStatusData] = useGetSettingData(EnumFields.PROFILE_STATUS);

    const [current, setCurrent] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [selectKeys, setSelectKeys] = React.useState<React.Key[]>([]);

    const [statusFilter, setStatusFilter] = React.useState<string>(EStatus.Active);
    const [statusUpdating, setStatusUpdating] = React.useState(false);
    const [isViewLoading, setIsViewLoading] = React.useState(false);

    const [updateFlagStatuList, setUpdateFlagStatuList] = useState<Array<boolean>>([]);

    const [productFilterList, setProductFilterList] = useState<Array<EProduct>>([]);

    useEffect(() => {
        const auditStatus: CheckboxOptionType[] = auditStatusData?.map((item) => ({ label: item.content, value: item.dictKey })) || [];
        const listingStatus: CheckboxOptionType[] = listingStatusData?.map((item) => ({
            label: capitalize(item.content?.replace(/(UniProfile\s|-)/g, (match: any) => {
                if (match === '-') {
                    return ' '; // 将 '-' 替换为空格
                }
                return ''; // 去掉 'UniProfile '
            })),
            value: item.dictKey,
        })) || [];
        setAuditStatusOptions(auditStatus);
        setListingStatusOptions(listingStatus);
    }, [auditStatusData, listingStatusData]);

    const defaultPrams = useRef<GetOpsUniprofileInput>({
        nameCond: pendingNameFilter,
        providerId: pendingIdFilter,
        auditStatusList: aduitList || [],
        uniprofileStatusList: listingStatusList || [],
        orderKey: 'create_time',
        orderType: 'desc',
        current: 1,
        size: 10,
        assignee: pendingAssigneeFilter,
        providerPayPlanTypeList: planTypeList || [],
        payStatusList: payStatusList || [],
        archiveStatusList: statusFilter === allValue ? [] : [statusFilter],
        sourceList: signUpFromList || [],
        promotionCodeList: promotionCodeList || [],
    });

    const currentFilter: Partial<GetOpsUniprofileInput> = useMemo(() => {
        const data: Partial<GetOpsUniprofileInput> = {
            nameCond: pendingNameFilter,
            assignee: pendingAssigneeFilter,
            auditStatusList: aduitList,
            uniprofileStatusList: listingStatusList,
            providerPayPlanTypeList: planTypeList,
            payStatusList,
            archiveStatusList: statusFilter === allValue ? [] : [statusFilter],
            sourceList: signUpFromList || [],
            promotionCodeList: promotionCodeList || [],
        };
        if (productFilterList && productFilterList.length === 1) {
            data.productList = [productFilterList[0]];
        }
        if (updateFlagStatuList && updateFlagStatuList.length === 1) {
            data.updateFlagList = [updateFlagStatuList[0]];
        }
        return data;
    }, [
        pendingNameFilter,
        pendingAssigneeFilter,
        aduitList,
        listingStatusList,
        planTypeList,
        payStatusList,
        statusFilter,
        signUpFromList,
        updateFlagStatuList,
        productFilterList,
    ]);

    const { data, loading, run } = useRequest(getUniprofileList, {
        defaultParams: [
            defaultPrams.current,
        ],
    });

    const { data: contractVersionData, loading: contractVersionDataLoading } = useRequest(getContractVersionWithType);

    const downloadFile = useCallback(async () => {
        try {
            const response = await exportProviderList();
            if (response && !response.error && response.data) {
                const csvData = response.data;
                const fileName = `Providers_${dayjs().format('MM/DD/YYYY')}.csv`;
                downloadFileWithBlob(csvData, fileName);
            } else {
                message.error(response?.error);
            }
        } catch (e) {
            console.error(e);
        }
    }, []);

    const { loading: exportLoading, run: exportRun } = useRequest(downloadFile, {
        manual: true,
    });

    const listData: ProviderWithUniprofileStatus[] = data?.data?.data.records || [];
    const total = data?.data?.data.total || 0;

    const handleTableChange = useCallback((paginationData?: TablePaginationConfig, filtersData?: Partial<GetOpsUniprofileInput> & { contract?: string[] }, sorter?: any, action?: any) => {
        const params = defaultPrams.current;

        if (!paginationData) {
            setCurrent(1);
        } else {
            setCurrent(paginationData?.current!);
            setPageSize(paginationData?.pageSize!);
        }

        params.current = paginationData ? paginationData.current! : 1;

        if (action?.action === 'sort') {
            params.orderType = sorter.order === 'ascend' ? 'asc' : 'desc';
            if (sorter.field === 'firstLaunchAt') {
                params.orderKey = 'first_launch_at';
            } else if (sorter.field === 'createTime') {
                params.orderKey = 'create_time';
            }
        }

        if (filtersData?.contract) {
            params.contractVersion = filtersData?.contract;
        }
        params.nameCond = filtersData?.nameCond || currentFilter.nameCond;
        params.providerId = filtersData?.providerId || currentFilter.providerId;
        params.uidPublic = filtersData?.uidPublic || currentFilter.uidPublic;
        params.auditStatusList = filtersData?.auditStatusList || currentFilter.auditStatusList;
        params.uniprofileStatusList = filtersData?.uniprofileStatusList || currentFilter.uniprofileStatusList;
        params.assignee = filtersData?.assignee || currentFilter.assignee;
        params.providerPayPlanTypeList = filtersData?.providerPayPlanTypeList || currentFilter.providerPayPlanTypeList;
        params.payStatusList = filtersData?.payStatusList || currentFilter.payStatusList || [];
        params.archiveStatusList = filtersData?.archiveStatusList || currentFilter.archiveStatusList || [];
        params.sourceList = filtersData?.sourceList || currentFilter.sourceList || [];
        params.promotionCodeList = filtersData?.promotionCodeList || currentFilter.promotionCodeList || [];
        params.updateFlagList = filtersData?.updateFlagList || currentFilter.updateFlagList;
        params.productList = filtersData?.productList || currentFilter.productList;
        if (params?.providerPayPlanTypeList?.includes(TSubscriptionType.KLARITY_STARTER)) {
            params?.providerPayPlanTypeList?.push(TSubscriptionType.KLARITY);
        }

        run(params);
    }, [currentFilter, run]);

    const filterNameDebounce = useRef(debounce((params) => {
        handleTableChange(undefined, params);
    }, 500));

    const filterIdDebounce = useRef(debounce((params) => {
        handleTableChange(undefined, params);
    }, 500));

    const filterUidDebounce = useRef(debounce((params) => {
        handleTableChange(undefined, params);
    }, 500));

    const filterAssigneeDebounce = useRef(debounce((params) => {
        handleTableChange(undefined, params);
    }, 500));

    const handleListingStatusFilterCheckAll = useCallback((e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && listingStatusOptions ? listingStatusOptions.map((option: CheckboxOptionType) => option.value) : [];

        setListingStatusList(checkedListData as string[]);
        setListingStatusIndeterminate(false);
        setListingStatusCheckAll(e.target.checked);

        handleTableChange(undefined, {
            nameCond: pendingNameFilter,
            auditStatusList: aduitList,
            productList: productFilterList,
            uniprofileStatusList: checkedListData as string[],
            providerId: pendingIdFilter,
            uidPublic: pendingUidFilter,
        });
    }, [aduitList, handleTableChange, listingStatusOptions, pendingNameFilter]);

    const handleSignUpFromFilterCheckAll = useCallback(
        (e: CheckboxChangeEvent) => {
            const checkedListData =
                e.target.checked && signUpFromOptions
                    ? signUpFromOptions.map(
                        (option: Meta) => option.value,
                    )
                    : [];

            setSignUpFromList(checkedListData as string[]);
            setSignUpFromIndeterminate(false);
            setSignUpFromCheckAll(e.target.checked);

            handleTableChange(undefined, {
                ...currentFilter,
                sourceList: checkedListData as string[],
            });
        },
        [handleTableChange, signUpFromOptions, currentFilter],
    );

    const handleSignUpFromFilterChange = useCallback(
        (sourceList: string[]) => {
            setSignUpFromList(sourceList);
            setSignUpFromIndeterminate(
                !!sourceList.length &&
                !!signUpFromOptions &&
                sourceList.length < signUpFromOptions.length,
            );
            setSignUpFromCheckAll(
                sourceList.length === signUpFromOptions?.length,
            );

            handleTableChange(undefined, {
                ...currentFilter,
                sourceList,
            });
        },
        [
            signUpFromOptions,
            handleTableChange,
            currentFilter,
        ],
    );

    const handlePromotionCodeFilterCheckAll = useCallback(
        (e: CheckboxChangeEvent) => {
            const checkedListData =
                e.target.checked && promotionCodeOptions
                    ? promotionCodeOptions.map(
                        (option: Meta) => option.value,
                    )
                    : [];

            setPromotionCodeList(checkedListData as string[]);
            setPromotionCodeIndeterminate(false);
            setPromotionCodeCheckAll(e.target.checked);

            handleTableChange(undefined, {
                ...currentFilter,
                promotionCodeList: checkedListData as string[],
            });
        },
        [handleTableChange, signUpFromOptions, currentFilter],
    );

    const handlePromotionCodeFilterChange = useCallback(
        (sourceList: string[]) => {
            setPromotionCodeList(sourceList);
            setPromotionCodeIndeterminate(
                !!promotionCodeList.length &&
                !!promotionCodeOptions &&
                promotionCodeList.length < promotionCodeOptions.length,
            );
            setPromotionCodeCheckAll(
                promotionCodeList.length === promotionCodeOptions?.length,
            );
            handleTableChange(undefined, {
                ...currentFilter,
                promotionCodeList: sourceList,
            });
        },
        [
            promotionCodeOptions,
            handleTableChange,
            currentFilter,
        ],
    );

    const handlePlanTypeFilterCheckAll = (e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && planTypeOptions ? planTypeOptions.map((option: CheckboxOptionType) => option.value) : [];

        setPlanTypeList(checkedListData as string[]);
        setPlanTypeIndeterminate(false);
        setPlanTypeCheckAll(e.target.checked);

        handleTableChange(undefined, {
            ...currentFilter,
            providerPayPlanTypeList: checkedListData as string[],
        });
    };

    const handlePlanTypeFilterChange = (planTypeListParm: CheckboxValueType[]) => {
        setPlanTypeList(planTypeListParm as string[]);
        setPlanTypeIndeterminate(!!planTypeListParm.length && !!planTypeOptions && planTypeListParm.length < planTypeOptions.length);
        setPlanTypeCheckAll(planTypeListParm.length === planTypeOptions?.length);

        handleTableChange(undefined, {
            ...currentFilter,
            providerPayPlanTypeList: planTypeListParm as string[],
        });
    };

    const handlePayStatusFilterCheckAll = (e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && payStatusOptions ? payStatusOptions.map((option: CheckboxOptionType) => option.value) : [];

        setPayStatusList(checkedListData as string[]);
        setPayStatusIndeterminate(false);
        setPayStatusCheckAll(e.target.checked);

        handleTableChange(undefined, {
            ...currentFilter,
            payStatusList: checkedListData as string[],
        });
    };

    const handlePayStatusFilterChange = (payStatusListParm: string[]) => {
        setPayStatusList(payStatusListParm);
        setPayStatusIndeterminate(!!payStatusListParm.length && !!planTypeOptions && payStatusListParm.length < payStatusOptions!?.length);
        setPayStatusCheckAll(payStatusListParm.length === payStatusOptions?.length);

        handleTableChange(undefined, {
            ...currentFilter,
            payStatusList: payStatusListParm,
        });
    };

    const handleAduitStatusFilterCheckAll = useCallback((e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && auditStatusOptions ? auditStatusOptions.map((option: CheckboxOptionType) => option.value) : [];

        setAduitList(checkedListData as string[]);
        setAduitStatusIndeterminate(false);
        setAduitCheckAll(e.target.checked);

        handleTableChange(undefined, {
            nameCond: pendingNameFilter,
            auditStatusList: checkedListData as string[],
            uniprofileStatusList: listingStatusList,
        });
    }, [auditStatusOptions, handleTableChange, listingStatusList, pendingNameFilter]);

    const handleListingStatusFilterChange = useCallback((statusList: string[]) => {
        setListingStatusList(statusList);
        setListingStatusIndeterminate(!!statusList.length && !!listingStatusOptions && statusList.length < listingStatusOptions.length);
        setListingStatusCheckAll(statusList.length === listingStatusOptions?.length);

        handleTableChange(undefined, {
            nameCond: pendingNameFilter,
            auditStatusList: aduitList,
            uniprofileStatusList: statusList,
        });
    }, [aduitList, handleTableChange, listingStatusOptions, pendingNameFilter]);

    const handleAduitStatusFilterChange = useCallback((statusList: CheckboxValueType[]) => {
        setAduitList(statusList as string[]);
        setAduitStatusIndeterminate(!!statusList.length && !!auditStatusOptions && statusList.length < auditStatusOptions.length);
        setAduitCheckAll(statusList.length === auditStatusOptions?.length);

        handleTableChange(undefined, {
            nameCond: pendingNameFilter,
            auditStatusList: statusList as string[],
            uniprofileStatusList: listingStatusList,
        });
    }, [auditStatusOptions, handleTableChange, listingStatusList, pendingNameFilter]);

    const handleStatusFilterChange = (status: string) => {
        setStatusFilter(status);

        handleTableChange(undefined, {
            ...currentFilter,
            archiveStatusList: status === allValue ? [] : [status],
        });
    };

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectKeys(selectedRowKeys || []);
        },
        selectedRowKeys: selectKeys,
    };

    const selectItems = listData.filter((item: ProviderWithUniprofileStatus) => selectKeys.includes(item.id));
    const hasSelect = selectItems.length > 0;
    let allActive = !!hasSelect;
    let allArchived = !!hasSelect;
    selectItems?.forEach((item: ProviderWithUniprofileStatus) => {
        if (item.archiveStatus === EStatus.Active) {
            allArchived = false;
        }
        if (item.archiveStatus === EStatus.Archived) {
            allActive = false;
        }
    });

    const setStatus = async (val: EStatus) => {
        setStatusUpdating(true);
        const result = await updateProviderStatusByIds(selectKeys as any, val);
        if (result.error) {
            //message.error(result.error);
        } else {
            const msg = val === EStatus.Active ? 'Activate successfully' : 'Archived successfully';
            message.success(msg);
            setSelectKeys([]);
            const newFilter = { ...currentFilter };
            if (newFilter?.providerPayPlanTypeList?.includes(TSubscriptionType.KLARITY_STARTER)) {
                newFilter?.providerPayPlanTypeList?.push(TSubscriptionType.KLARITY);
            }
            run({
                ...newFilter,
                current: 1,
                size: 10000,
            });
        }
        setStatusUpdating(false);
    };

    const handlePreviewPdf = async (providerId: number) => {
        setIsViewLoading(true);
        const { data: pdfData } = await getProviderViewPdf({
            providerId,
        });
        if (pdfData?.data) {
            setIsViewLoading(false);
            pdfData?.data.map((item: string) => {
                window.open(item);
                return item;
            });
        }
        setIsViewLoading(false);
    };

    const contractVersionOptions = useMemo(() => {
        const restult = contractVersionData?.data?.data?.map((version: string) => ({
            text: version,
            value: version,
        })) || [];

        restult.push({
            text: '-',
            value: 'empty',
        });

        return restult;
    }, [contractVersionData]);

    const columns: ColumnsType<ProviderWithUniprofileStatus> = [
        {
            title: 'NAME',
            dataIndex: 'name',
            filterSearch: true,
            className: s.tableColumn,
            filterIcon: <SearchOutlined />,
            width: '250px',
            fixed: 'left',
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        value={pendingNameFilter}
                        onChange={(e) => {
                            const filterName = e.target.value;
                            setPendingNameFilter(filterName);
                            filterNameDebounce.current({
                                nameCond: filterName,
                                auditStatusList: aduitList,
                                uniprofileStatusList: listingStatusList,
                                providerId: pendingIdFilter,
                                uidPublic: pendingUidFilter,
                            });
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                </div>
            ),
            render: (name: string, { firstName, lastName, email, archiveStatus }: ProviderWithUniprofileStatus) => {
                return (
                    <div className={s.nameWrap}>
                        <div className={s.nameLeft}>
                            {
                                archiveStatus === EStatus.Active && <span className={s.activate} />
                            }
                            {
                                archiveStatus === EStatus.Archived && <span className={s.archive} />
                            }
                        </div>
                        <div className={s.nameRight}>
                            <div className={s.name}>{firstName} {lastName}</div>
                            <div className={s.email}>{email}</div>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'PLAN',
            dataIndex: 'providerPayPlanType',
            key: 'providerPayPlanType',
            width: '100px',
            className: s.tableColumn,
            filterDropdown: () => {
                return (
                    <div className={s.vertical} style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Checkbox style={{ marginBottom: '8px' }} indeterminate={planTypeIndeterminate} onChange={handlePlanTypeFilterCheckAll} checked={planTypeCheckAll}>
                            Check all
                        </Checkbox>
                        <Checkbox.Group options={planTypeOptions} value={planTypeList} onChange={handlePlanTypeFilterChange} />
                    </div>
                );
            },
            render: (providerPayPlanType) => {
                return (
                    <div>{providerPayPlanType}</div>
                );
            },
        },
        {
            title: 'PAYMENT',
            dataIndex: 'payStatus',
            key: 'payStatus',
            width: '180px',
            className: s.tableColumn,
            filterDropdown: () => {
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Checkbox indeterminate={payStatusIndeterminate} onChange={handlePayStatusFilterCheckAll} checked={payStatusCheckAll}>
                                Check all
                            </Checkbox>
                            <Checkbox.Group options={payStatusOptions} value={payStatusList} onChange={handlePayStatusFilterChange} />
                        </Space>
                    </div>
                );
            },
        },
        {
            title: 'UPDATED',
            dataIndex: 'updateFlag',
            key: 'updateFlag',
            width: '160px',
            className: s.tableColumn,
            filterDropdown: () => {
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Checkbox.Group
                                options={[
                                    {
                                        label: 'Yes',
                                        value: true,
                                    },
                                    {
                                        label: 'No',
                                        value: false,
                                    },
                                ]}
                                value={updateFlagStatuList}
                                onChange={(val: boolean[]) => {
                                    setUpdateFlagStatuList(val);
                                    const newFilter = { ...currentFilter };
                                    if (val && val.length === 1) {
                                        newFilter.updateFlagList = [val[0]];
                                    } else {
                                        delete newFilter.updateFlagList;
                                    }
                                    handleTableChange(undefined, newFilter);
                                }}
                            />
                        </Space>
                    </div>
                );
            },
            render: (updateFlagList?: boolean) => {
                return (
                    <div>
                        {updateFlagList ? <Tag color="blue">Yes</Tag> : <Tag color="green">No</Tag>}
                    </div>
                );
            },
        },
        {
            title: 'USER ID',
            dataIndex: 'id',
            width: '120px',
            className: s.tableColumn,
            filterSearch: true,
            filterIcon: <SearchOutlined />,
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        value={pendingIdFilter}
                        onChange={(e) => {
                            const filterId = e.target.value;
                            setPendingIdFilter(filterId);
                            filterIdDebounce.current({
                                name: pendingNameFilter,
                                providerId: filterId,
                                auditStatusList: aduitList,
                                uniprofileStatusList: listingStatusList,
                                uidPublic: pendingUidFilter,
                            });
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                </div>
            ),
            render: (providerId: string) => {
                return (
                    <div>
                        {providerId}
                    </div>
                );
            },
        },
        {
            title: 'UID',
            dataIndex: 'uidPublic',
            width: '120px',
            className: s.tableColumn,
            filterSearch: true,
            filterIcon: <SearchOutlined />,
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        value={pendingUidFilter}
                        onChange={(e) => {
                            const filterId = e.target.value;
                            setPendingUidFilter(filterId);
                            filterUidDebounce.current({
                                name: pendingNameFilter,
                                providerId: pendingIdFilter,
                                auditStatusList: aduitList,
                                uniprofileStatusList: listingStatusList,
                                uidPublic: filterId,
                            });
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                </div>
            ),
            render: (providerId: string) => {
                return (
                    <div>
                        {providerId}
                    </div>
                );
            },
        },
        {
            title: 'PRODUCT',
            dataIndex: 'product',
            key: 'product',
            width: '160px',
            className: s.tableColumn,
            filterDropdown: () => {
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Checkbox.Group
                                options={[
                                    {
                                        label: 'Klarity',
                                        value: EProduct.KLARITY,
                                    },
                                    {
                                        label: 'Kiwi',
                                        value: EProduct.KIWI,
                                    },
                                ]}
                                value={productFilterList}
                                onChange={(val: CheckboxValueType[]) => {
                                    setProductFilterList(val as EProduct[]);
                                    const newFilter: any = { ...currentFilter };
                                    if (val && val.length === 1) {
                                        newFilter.productList = [val[0]];
                                    } else {
                                        delete newFilter.productList;
                                    }
                                    handleTableChange(undefined, newFilter);
                                }}
                            />
                        </Space>
                    </div>
                );
            },
            render: (product?: string) => {
                return (
                    <div>
                        {product}
                    </div>
                );
            },
        },
        {
            title: 'SIGNUP FROM',
            dataIndex: 'source',
            width: '220px',
            className: s.tableColumn,
            filterDropdown: () => {
                return (
                    <div className={s.vertical} style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Checkbox style={{ marginBottom: '8px' }} indeterminate={signUpFromIndeterminate} onChange={handleSignUpFromFilterCheckAll} checked={signUpFromCheckAll}>
                            Check all
                        </Checkbox>
                        <Checkbox.Group options={signUpFromOptions} value={signUpFromList} onChange={handleSignUpFromFilterChange} />
                    </div>
                );
            },
            render: (source: ESource) => {
                return <div>{ESourceName[source || 'W']}</div>;
            },
        },
        {
            title: 'COUPON',
            dataIndex: 'promotionCode',
            width: '220px',
            className: s.tableColumn,
            filterDropdown: () => {
                return (
                    <div className={s.vertical} style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Checkbox style={{ marginBottom: '8px' }} indeterminate={promotionCodeIndeterminate} onChange={handlePromotionCodeFilterCheckAll} checked={promotionCodeCheckAll}>
                            Check all
                        </Checkbox>
                        <Checkbox.Group options={promotionCodeOptions} value={promotionCodeList} onChange={handlePromotionCodeFilterChange} />
                    </div>
                );
            },
            render: (promotionCode: string) => {
                return <div>{promotionCode || '-'}</div>;
            },
        },
        {
            title: 'CONTRACT VERSION',
            dataIndex: 'contract',
            width: '210px',
            className: s.tableColumn,
            filters: contractVersionOptions,
            render: (contract: string[]) => {
                return (
                    <div>
                        {contract?.length > 0 ? contract?.map((item: string) => (
                            <div>{item}</div>
                        )) : '-'}
                    </div>
                );
            },
        },
        {
            title: 'SIGNUP DATE',
            dataIndex: 'createTime',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            width: '150px',
            className: s.tableColumn,
            render: (time) => {
                return (
                    <div>{time ? dayjs(time).format('MM/DD/YYYY') : 'Nil'}</div>
                );
            },
        },
        {
            title: 'UNIPROFILE ACTION',
            dataIndex: 'uniprofileStatusName',
            width: '250px',
            className: s.tableColumn,
            filterDropdown: () => {
                return (
                    <div style={{ padding: 8, width: '800px' }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Checkbox indeterminate={listingStatusIndeterminate} onChange={handleListingStatusFilterCheckAll} checked={listingStatusCheckAll}>
                                Check all
                            </Checkbox>
                            <Checkbox.Group value={listingStatusList} onChange={handleListingStatusFilterChange}>
                                <Row style={{ width: '650px' }}>
                                    {
                                        listingStatusOptions?.map((item, index) => {
                                            return (
                                                <Col key={index} span={8}>
                                                    <Checkbox value={item.value as string}>{item.label}</Checkbox>
                                                </Col>
                                            );
                                        })
                                    }
                                </Row>
                            </Checkbox.Group>
                        </Space>
                    </div>
                );
            },
        },
        {
            title: 'KLARITY LAUNCH DATE',
            dataIndex: 'firstLaunchAt',
            width: '250px',
            className: s.tableColumn,
            sorter: true,
            render: (date?: string) => (date ? dayjs(date).format('MM/DD/YYYY') : 'Not launched yet'),
        },
        {
            title: 'OPS PROGRESS',
            dataIndex: 'auditStatus',
            width: '280px',
            className: s.tableColumn,
            filterDropdown: () => {
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Checkbox indeterminate={aduitStatusIndeterminate} onChange={handleAduitStatusFilterCheckAll} checked={aduitCheckAll}>
                                Check all
                            </Checkbox>
                            <Checkbox.Group options={auditStatusOptions} value={aduitList} onChange={handleAduitStatusFilterChange} />
                        </Space>
                    </div>
                );
            },
            render: (status) => status && <ProfileStatusTag status={status} />,
        },
        {
            title: 'ASSIGNEE',
            dataIndex: 'assignee',
            filterSearch: true,
            className: s.tableColumn,
            filterIcon: <SearchOutlined />,
            width: '180px',
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        value={pendingAssigneeFilter}
                        onChange={(e) => {
                            const filterName = e.target.value;
                            setPendingAssigneeFilter(filterName);
                            filterAssigneeDebounce.current({
                                assignee: filterName,
                                name: pendingNameFilter,
                                channelName: pendingNameFilter,
                                auditStatusList: aduitList,
                                completeStatusList: listingStatusList,
                            });
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                </div>
            ),
            render: (assignee) => {
                return (
                    <div>{assignee || ''}</div>
                );
            },
        },
        {
            title: 'CONTRACT',
            width: '120px',
            className: s.tableColumn,
            fixed: 'right',
            render: (item) => {
                return (
                    <div>
                        {item.contract?.length > 0 ? (
                            <Button
                                className={s.link}
                                onClick={() => handlePreviewPdf(item.id)}
                                type="text"
                            >
                                View
                            </Button>
                        ) : (
                            ''
                        )}
                    </div>
                );
            },
        },
        {
            title: 'ACTION',
            width: '80px',
            className: s.tableColumn,
            fixed: 'right',
            render: (item: ProviderWithUniprofileStatus) => {
                return (
                    <div>
                        <Button
                            className={s.link}
                            onClick={() => {
                                let link = `${PATH.OPERATION}/${PATH.UNIPROFILE_DETAIL}/-1/${item.id}`;
                                if (item.updateFlag) {
                                    link += '?updated=true';
                                }
                                navigate(link);
                            }}
                            type="text"
                        >View
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <div className={s.wrap}>
            <div className={s.filter}>
                <div className={selectKeys?.length > 0 ? `${s.left} ${s.leftSelect}` : s.left}>
                    {
                        allArchived &&
                        <Button
                            loading={statusUpdating}
                            className={s.action}
                            onClick={() => { setStatus(EStatus.Active); }}
                        >
                            {Icons.CheckIcon}
                            <span className={s.label}>Set as active</span>
                        </Button>
                    }

                    {
                        allActive &&
                        <Button
                            loading={statusUpdating}
                            className={s.action}
                            onClick={() => { setStatus(EStatus.Archived); }}
                        >
                            {Icons.UnCheckIcon}
                            <span className={s.label}>Archive</span>
                        </Button>
                    }
                    <span />
                </div>
                <div className={s.right}>
                    <Button icon={<DownloadOutlined />} onClick={exportRun} loading={exportLoading}>Download</Button>
                    <Select
                        value={statusFilter}
                        style={{ width: 160, marginRight: 16 }}
                        onChange={handleStatusFilterChange}
                        options={
                            [
                                { value: allValue, label: 'All status' },
                                { value: 'Active', label: 'Active' },
                                { value: 'Archived', label: 'Archived' },
                            ]}
                    />
                </div>
            </div>
            <div className={s.tableWrap}>
                <Spin spinning={isViewLoading}>
                    <CommonTable<ProviderWithUniprofileStatus>
                        rowKey={(record: ProviderWithUniprofileStatus) => record.id}
                        bordered
                        loading={loading || contractVersionDataLoading}
                        columns={columns}
                        data={listData || []}
                        onChange={handleTableChange}
                        pagination={{
                            current,
                            pageSize,
                            total,
                        }}
                        scroll={{ x: '1500' }}
                        rowSelection={rowSelection}
                    />
                </Spin>
            </div>
        </div>
    );
};

export default Page;
