import { FormFields, IChannelListChannel, IEducationResponse, IProviderStore } from 'types/practiceFront';
import { PFProvider, ProviderExperience } from 'types/provider';
import dayjs from './dayjs';
import { INSURANCE_TYPE_CASH, INSURANCE_TYPE_CASH_AND_INSURANCE } from 'constants/common';

export const transferProviderToFormData = (data: PFProvider): IProviderStore => {
    let treatmentPhilosophy = data.providerPractice?.treatmentPhilosophy;
    if (!treatmentPhilosophy?.replace(/<\/?.+?>/g, '').replace(/ /g, '')) {
        treatmentPhilosophy = '';
    }

    let { supportPayType } = data;
    if (!data.supportPayType) {
        if ((data.insuranceList && data.insuranceList.length > 0) || (data.otherInsuranceList && data.otherInsuranceList.length > 0)) {
            supportPayType = INSURANCE_TYPE_CASH_AND_INSURANCE;
        } else {
            supportPayType = INSURANCE_TYPE_CASH;
        }
    }

    let seoCity;
    if (data.seoCity) {
        try {
            seoCity = JSON.parse(data.seoCity);
        } catch (e) {
            console.error(e);
        }
    }

    return {
        email: data.email,
        appointmentIntegrationUrl: data.appointmentIntegrationUrl,
        useIntegrationUrl: data.useIntegrationUrl,
        appointmentNewFlag: data.appointmentNewFlag,
        basicInfo: {
            [FormFields.ID]: data.id,
            [FormFields.FIRST_NAME]: data.firstName,
            [FormFields.LAST_NAME]: data.lastName,
            // [FormFields.ADDRESS]: data.address,
            // [FormFields.CITY]: data.city,
            // [FormFields.ZIP_CODE]: data.zip,
            // [FormFields.STATE]: data.state,
            // [FormFields.LICENSES]: data.licenseDtoList,
            [FormFields.PROVIDER_PRACTICE]: data.providerPractice,
            [FormFields.CREDENTIAL]: data.credential?.split(','),
            [FormFields.TITLE]: data.title,
            [FormFields.PHOTO]: data.providerPractice?.practiceLogo,
        },
        insurance: {
            [FormFields.INSURANCE_LIST]: data.insuranceList,
            [FormFields.OTHER_INSURANCE_LIST]: data.otherInsuranceList,
            [FormFields.SUPPORT_PAY_TYPE]: supportPayType,
        },
        channels: data.channelList.filter((e: IChannelListChannel) => e.name !== 'Practice Front'),
        aboutMe: {
            intro: data.intro,
            yearExp: data.yearExp,
            headLine: data.headLine,
            photo: data.photo || data.photoList?.[0],
        },
        myPhilosophy: {
            [FormFields.TREATMENT_PHILOSOPHY]: treatmentPhilosophy,
            [FormFields.TREATMENT_APPROACH]: data.providerPractice?.treatmentApproach,
        },
        specialtiesApproaches: {
            [FormFields.SPECIALTY_LIST]: data.providerPractice?.specialtyList,
            [FormFields.CONDITION_TREATED_LIST]: data.providerPractice?.conditionTreatedList,
        },
        experience: data.providerExperienceList?.map((experience: ProviderExperience) => ({
            ...experience,
            startDate: experience.startDate ? dayjs(experience.startDate) : undefined,
            endDate: !experience.currently && experience.endDate ? dayjs(experience.endDate) : undefined,
        })),
        education: data.providerEducationList?.map((education: IEducationResponse) => ({
            ...education,
            startDate: education.startDate ? dayjs(education.startDate) : undefined,
            endDate: !education.currently && education.endDate ? dayjs(education.endDate) : undefined,
        })),
        service: data.providerServiceList,
        contact: data.providerPractice,
        seoCity,
        seoData: data.seoData,
        containSeoApp: data.containSeoApp,
        pfKlarityServiceList: data.pfKlarityServiceList,
    };
};
