// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--VRwGN {\n  padding: 48px;\n  background: var(--main-bg-color);\n}\n.s-module__wrap--VRwGN :where(.css-dev-only-do-not-override-1uhj8f6).ant-tabs .ant-tabs-tab-btn {\n  font-family: Inter;\n  font-size: 20px;\n  font-weight: 500;\n  line-height: 28px;\n  text-align: left;\n}\n.s-module__wrap--VRwGN .s-module__title--bprx6 {\n  margin-bottom: 24px;\n  font-family: Inter;\n  font-size: 30px;\n  font-weight: 800;\n  line-height: 36px;\n  text-align: left;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/operation/ServiceTemplateManagement/s.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,gCAAA;AACJ;AAHA;EAMY,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAAZ;AAVA;EAeQ,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAFR","sourcesContent":[".wrap {\n    padding: 48px;\n    background: var(--main-bg-color);\n\n    :global {\n        :where(.css-dev-only-do-not-override-1uhj8f6).ant-tabs .ant-tabs-tab-btn {\n            font-family: Inter;\n            font-size: 20px;\n            font-weight: 500;\n            line-height: 28px;\n            text-align: left;\n        }\n    }\n\n    .title {\n        margin-bottom: 24px;\n        font-family: Inter;\n        font-size: 30px;\n        font-weight: 800;\n        line-height: 36px;\n        text-align: left;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--VRwGN",
	"title": "s-module__title--bprx6"
};
export default ___CSS_LOADER_EXPORT___;
