// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--sIl4o {\n  background: var(--main-bg-color);\n  min-height: 100vh;\n  padding: 36px 48px;\n}\n.s-module__wrap--sIl4o h2 {\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/operation/ProviderSearchPage/s.module.less"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,iBAAA;EACA,kBAAA;AACF;AAJA;EAKI,kBAAA;AAEJ","sourcesContent":[".wrap {\n  background: var(--main-bg-color);\n  min-height: 100vh;\n  padding: 36px 48px;\n  h2 {\n    text-align: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--sIl4o"
};
export default ___CSS_LOADER_EXPORT___;
