// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--rWBSH .s-module__block--lKwtW .s-module__title--aW9GK {\n  padding: 10px 0 6px;\n  border-bottom: 1px solid var(--gray-300);\n  font-family: Inter;\n  font-size: 20px;\n  font-weight: 500;\n  line-height: 28px;\n  text-align: left;\n  color: var(--gray-800);\n}\n.s-module__wrap--rWBSH .s-module__block--lKwtW .s-module__counts--cMUUG {\n  margin-top: 24px;\n}\n.s-module__wrap--rWBSH .s-module__block--lKwtW .s-module__item--Cxor8 {\n  margin: 8px 0 24px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.s-module__wrap--rWBSH .s-module__block--lKwtW .s-module__item--Cxor8 .s-module__searchSelctor--grhOE {\n  width: 446px;\n}\n.s-module__wrap--rWBSH .s-module__actionBtn--hUPB2 {\n  color: var(--main-green);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/operation/KlarityProgramDetailPage/components/PackageDetail/components/IncludedItems/s.module.less"],"names":[],"mappings":"AAAA;EAGY,mBAAA;EACA,wCAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,sBAAA;AADZ;AATA;EAaY,gBAAA;AADZ;AAZA;EAgBY,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AADZ;AAlBA;EAsBgB,YAAA;AADhB;AArBA;EA4BQ,wBAAA;AAJR","sourcesContent":[".wrap {\n    .block {\n        .title {\n            padding: 10px 0 6px;\n            border-bottom: 1px solid var(--gray-300);\n            font-family: Inter;\n            font-size: 20px;\n            font-weight: 500;\n            line-height: 28px;\n            text-align: left;\n            color: var(--gray-800);\n        }\n        .counts {\n            margin-top: 24px;\n        }\n        .item {\n            margin: 8px 0 24px;\n            display: flex;\n            align-items: center;\n            justify-content: space-between;\n\n            .searchSelctor {\n                width: 446px;\n            }\n        }\n    }\n\n    .actionBtn {\n        color: var(--main-green);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--rWBSH",
	"block": "s-module__block--lKwtW",
	"title": "s-module__title--aW9GK",
	"counts": "s-module__counts--cMUUG",
	"item": "s-module__item--Cxor8",
	"searchSelctor": "s-module__searchSelctor--grhOE",
	"actionBtn": "s-module__actionBtn--hUPB2"
};
export default ___CSS_LOADER_EXPORT___;
