// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--AK5nL {\n  background: var(--main-bg-color);\n  min-height: 100vh;\n  padding: 36px 48px;\n}\n.s-module__wrap--AK5nL .s-module__header--CuWKr {\n  border-bottom: 1px solid #eee;\n  padding-bottom: 12px;\n  margin-bottom: 24px;\n}\n.s-module__wrap--AK5nL .s-module__header--CuWKr h1 {\n  color: #111827;\n  font-size: 30px;\n  font-weight: bolder;\n  line-height: 36px;\n}\n.s-module__wrap--AK5nL .s-module__tabTitle--nHKcC {\n  text-align: center;\n  min-width: 300px;\n  color: var(--gray-500);\n  font-weight: 500;\n}\n.s-module__wrap--AK5nL .ant-tabs-tab-active p {\n  color: var(--primary-color);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/operation/KiwiHealthPage/s.module.less"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,iBAAA;EACA,kBAAA;AACF;AAJA;EAKI,6BAAA;EACA,oBAAA;EACA,mBAAA;AAEJ;AATA;EASM,cAAA;EACA,eAAA;EACA,mBAAA;EACA,iBAAA;AAGN;AAfA;EAgBI,kBAAA;EACA,gBAAA;EACA,sBAAA;EACA,gBAAA;AAEJ;AArBA;EAwBQ,2BAAA;AAAR","sourcesContent":[".wrap {\n  background: var(--main-bg-color);\n  min-height: 100vh;\n  padding: 36px 48px;\n  .header{\n    border-bottom: 1px solid #eee;\n    padding-bottom: 12px;\n    margin-bottom: 24px;\n    h1{\n      color: #111827;\n      font-size: 30px;\n      font-weight: bolder;\n      line-height: 36px;\n    }\n  }\n  .tabTitle{\n    text-align: center;\n    min-width: 300px;\n    color: var(--gray-500);\n    font-weight: 500;\n  }\n  :global{\n    .ant-tabs-tab-active{\n      p{\n        color: var(--primary-color);\n      }\n    }\n  }\n  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--AK5nL",
	"header": "s-module__header--CuWKr",
	"tabTitle": "s-module__tabTitle--nHKcC"
};
export default ___CSS_LOADER_EXPORT___;
