// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__button--sLjSo {\n  display: flex;\n  align-items: center;\n  background-color: #fff !important;\n}\n.s-module__button--sLjSo span {\n  margin-left: 8px;\n  color: var(--gray-700, #374151);\n  font-weight: 500;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/operation/HelloKlarityReviews/components/OpenGoogleForm/s.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,iCAAA;AACF;AAJA;EAKI,gBAAA;EACA,+BAAA;EACA,gBAAA;AAEJ","sourcesContent":[".button {\n  display: flex;\n  align-items: center;\n  background-color: #fff!important;\n  span {\n    margin-left: 8px;\n    color: var(--gray-700, #374151);\n    font-weight: 500;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "s-module__button--sLjSo"
};
export default ___CSS_LOADER_EXPORT___;
