// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__module--L2B6G {\n  background: #fff;\n  height: auto;\n}\n.s-module__module--L2B6G .s-module__row--Y6z9A {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  margin-top: 16px;\n}\n.s-module__module--L2B6G .s-module__row--Y6z9A .s-module__displayItem--wUxxu {\n  margin-bottom: 12px;\n}\n.s-module__module--L2B6G .s-module__row--Y6z9A .s-module__displayItem--wUxxu .s-module__label--lbd11 {\n  color: #6B7280;\n  font-size: 14px;\n  line-height: 20px;\n}\n.s-module__module--L2B6G .s-module__rowItem--VP0Ng {\n  margin-right: 36px;\n}\n.s-module__module--L2B6G .s-module__colItem--U3RV2 {\n  display: flex;\n  flex-direction: column;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/operation/HelloKlarityPageManagement/components/FaqManagement/components/EditFaqModal/s.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;AACF;AAHA;EAKI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AACJ;AATA;EAeM,mBAAA;AAHN;AAZA;EAWQ,cAAA;EACA,eAAA;EACA,iBAAA;AAIR;AAjBA;EAoBI,kBAAA;AAAJ;AApBA;EAwBI,aAAA;EACA,sBAAA;AADJ","sourcesContent":[".module {\n  background: #fff;\n  height: auto;\n\n  .row {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    margin-top: 16px;\n    .displayItem{\n      .label {\n        color: #6B7280;\n        font-size: 14px;\n        line-height: 20px;\n      }\n      margin-bottom: 12px;\n    }\n  }\n\n  .rowItem {\n    margin-right: 36px;\n  }\n\n  .colItem {\n    display: flex;\n    flex-direction: column;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"module": "s-module__module--L2B6G",
	"row": "s-module__row--Y6z9A",
	"displayItem": "s-module__displayItem--wUxxu",
	"label": "s-module__label--lbd11",
	"rowItem": "s-module__rowItem--VP0Ng",
	"colItem": "s-module__colItem--U3RV2"
};
export default ___CSS_LOADER_EXPORT___;
