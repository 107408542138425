import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useLocation } from 'react-router-dom';
import { Channel, EditLocalSEOInfoServer, EmailContentItem, License, LocalSEOInfo, LocalSEOInfoServer, Meta, Prospect, ServerMeta } from 'types/common';
import { ENV_VAR, OPS_ENV, PermissionModuleKeys } from 'constants/access';
import AccessDeniedPage from 'pages/AccessManagement/AccessDenied';
import { PracitceAddressForm } from 'types/practiceFront';
import { SettingEnum } from 'types/enumerationData';
import { ITimePair } from 'types/appointmentTime';
import { getUserId } from './localstore';
import { SHOW_KLARITY_ADVERTISING_ICON } from 'constants/common';
import { dontShowAdvertisingPaht } from 'constants/path';
import { TSubscriptionType, TSubscriptionItemFromServer } from 'types/subscription';

export const isMobile = () => {
    const check = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) && window.screen.width <= 600;
    return !!check;
};

export const isLocalhost = () => {
    const check = window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1');
    return !!check;
};

export const isProductionEnv = () => {
    const check = !isLocalhost() && !window.location.hostname.includes('dev.') && !window.location.hostname.includes('test.');

    return !!check;
};

export const isTestEnv = () => {
    const check = window.location.hostname.includes('test.');

    return !!check;
};

export const isDevEnv = () => {
    const check = window.location.hostname.includes('dev.') || isLocalhost();

    return !!check;
};

export const formatDate = (date?: dayjs.Dayjs, format?: string) => {
    if (!date) {
        return '';
    }
    return date.format(format || 'YYYY-MM-DD');
};

export const formatUtcDate = (date?: dayjs.Dayjs) => {
    if (!date) {
        return '';
    }
    return date.utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
};

export const getFileNameFromUrl = (url?: string) => {
    if (!url || typeof url !== 'string') {
        return '';
    }
    return url?.substring(url.lastIndexOf('/') + 1);
};

export const jumpToFormError = (dom?: HTMLElement) => {
    setTimeout(() => {
        const errors: any = document.getElementsByClassName('ant-form-item-explain-error');
        const target: any = errors?.[0]?.offsetParent;
        if (target) {
            const useDom = dom && dom.scroll;
            if (useDom) {
                dom.scroll({
                    top: target ? target.offsetTop + 100 : 300,
                    behavior: 'smooth',
                });
                return;
            }
            window.scroll({
                top: target ? target.offsetTop + 100 : 300,
                behavior: 'smooth',
            });
        }
    }, 500);
};

type GetEditableStatus = {
    allowEditOnBoarding?: boolean,
    allowEditChannel?: boolean
};

export const getNewEditableStatus = (props:
    {
        currentEditable?: string,
        allowEditOnBoarding?: boolean,
        allowEditChannel?: boolean
    }): string => {
    const { currentEditable, allowEditOnBoarding, allowEditChannel } = props;
    const currentEditableInt = currentEditable ? parseInt(currentEditable, 10) : 0;
    let currentAllowEditOnBoarding = (currentEditableInt & 1) > 0;
    let currentAllowEditallowEditChannel = (currentEditableInt & 2) > 0;
    if (allowEditOnBoarding !== undefined) {
        currentAllowEditOnBoarding = allowEditOnBoarding;
    }
    if (allowEditChannel !== undefined) {
        currentAllowEditallowEditChannel = allowEditChannel;
    }
    let ret = 0;
    if (currentAllowEditOnBoarding) {
        ret |= 1;
    }
    if (currentAllowEditallowEditChannel) {
        ret |= 2;
    }
    return `${ret}`;
};

export const getEditableStatus = (currentEditable?: string): GetEditableStatus => {
    const currentEditableInt = currentEditable ? parseInt(currentEditable, 10) : 0;
    const currentAllowEditOnBoarding = (currentEditableInt & 1) > 0;
    const currentAllowEditallowEditChannel = (currentEditableInt & 2) > 0;
    return {
        allowEditOnBoarding: currentAllowEditOnBoarding,
        allowEditChannel: currentAllowEditallowEditChannel,
    };
};

export const getBrowserTimezone = (): string => {
    return new window.Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getBrowserTimezoneOffset = (): string => {
    const minOff = new Date().getTimezoneOffset();
    const hour = -minOff / 60;
    if (hour > 0) {
        if (hour < 10) {
            return `+0${hour}`;
        }
        return `+${hour}`;
    }
    if (hour > -10) {
        return `-0${Math.abs(hour)}`;
    }
    return `-${Math.abs(hour)}`;
};

export const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

// export const removeHtmlTag = (str: string = '') => str?.replace(/<.*?>/g, '')?.replace(/\r|\n|(\r\n)/g, '') || '';
export const removeHtmlTag = (str: string = '') => str?.replace(/<[^>]+>|[\r\n]/g, '') || '';
export const removeHtmlTagButWrap = (str: string = '') => str?.replace(/<.*?>/g, '') || '';

export const isOpPortal = () => { return !!window.location.hostname.includes('internal.boxpractice') || localStorage.getItem(ENV_VAR) === OPS_ENV; };

export const getUrlParam = (key: string): string | undefined => {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (pair[0] === key) { return decodeURIComponent(pair[1]); }
    }
    return undefined;
};

export const insertUrlParam = (key: string, value: string) => {
    if (!value) {
        return;
    }
    if (window.history.replaceState) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(key, value);
        const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${searchParams.toString()}`;
        window.history.replaceState({ path: newurl }, '', newurl);
    }
};

export const removeUrlParam = (key: string) => {
    if (window.history.replaceState) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete(key);
        let newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${searchParams.toString()}`;
        if (searchParams.keys.length === 0) {
            newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
        }
        window.history.replaceState({ path: newurl }, '', newurl);
    }
};

export const getStrWithLineBreaker = (str: string) => {
    return str?.split('\n').map((s) => <p>{s}</p>);
};

export const stripHtmlTags = (str: string) => {
    if (!str) return str;
    return str.replace(/(<([^>]+)>)/gi, '');
};

export const getAgeFromBirthday = (date?: string): number | undefined => {
    if (!date) {
        return undefined;
    }
    const current = (new Date()).getFullYear();
    const birthday = dayjs(date).year();
    return current - birthday;
};

export const isLicenseExpired = (license: License): boolean => {
    const { expireDate } = license;
    if (!expireDate) {
        return false;
    }
    return dayjs().valueOf() - dayjs(expireDate).valueOf() > 0;
};

export const onlyOneFieldHasValue = (params: Array<string>) => {
    let sum = 0;
    for (let i = 0; i < params.length; i++) {
        sum += Number(!!params[i]);
    }
    return sum === 1;
};

export const optimizeSnippet = (snippet: string): string => {
    if (!snippet) {
        return snippet;
    }
    const inx = snippet.indexOf('If you have any further que');
    if (inx > -1) {
        return snippet.substring(0, inx);
    }
    return snippet;
};

export const getEmailDisplay = (email: EmailContentItem): string => {
    if (!email) {
        return '';
    }
    const contentWithHtml = email.content || '';
    const regex = /\s{3,}/g;
    const regexHtml = /<("[^"]*"|'[^']*'|[^'">])*>/;
    const isContentHasHtml = regexHtml.test(email.content);
    let contentWithoutHtml = email.body || '';
    //contentWithoutHtml = email.body || '' //new add
    //const { snippet } = email;
    // if (contentWithHtml.indexOf('<div style="margin-top: 16px;font-weight:500">\n') > 0) {
    //     //email template from practice front
    //     const content = contentWithHtml.substring(contentWithHtml.indexOf('<div style="margin-top: 16px;font-weight:500">\n'));
    //     const endPoint = content.indexOf('</div>\n ');
    //     if (endPoint > 0) {
    //         return content.substring(0, endPoint).replace('<div style="margin-top: 16px;font-weight:500">\n', '');
    //     }
    // }
    // if (contentWithHtml.indexOf('Message Content:') > 0 && contentWithHtml.indexOf('Please note that this e') > 0) {
    //     //email template from practice front
    //     const content = contentWithHtml.substring(contentWithHtml.indexOf('Message Content:'), contentWithHtml.indexOf('Please note that this e'));
    //     return content.replace('Message Content:', '').replace(/\*/g, '').replace('\r\n', '');
    // }
    if (
        contentWithHtml.includes(
            'hesitate to contact me by responding to this email',
        ) &&
        contentWithHtml.startsWith(
            '<!DOCTYPE html>\n<html lang="en_US" xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"\n',
        )
    ) {
        //email from replay
        const content = contentWithHtml.substring(
            contentWithHtml.indexOf(
                '<div style="margin-top: 16px;font-weight:500;text-align: left">',
            ),
        );
        const endPoint = content.indexOf('</div>');
        if (endPoint > 0) {
            return content
                .substring(0, endPoint)
                .replace(
                    '<div style="margin-top: 16px;font-weight:500;text-align: left">',
                    '',
                )
                .trim();
        }
    }
    // contentWithoutHtml = contentWithoutHtml
    //     ?.replace(
    //         /Hello,.+?\r\nYou've received a new message from .+?\r\n(.+?)/,
    //         '$1',
    //     )
    //     .replace(
    //         /If you have any further questions or require additional assistance, please(?:\s*\r?\n)?don't hesitate to contact me by responding to this email(?:\.\r?\n)?/,
    //         '',
    //     );

    return contentWithoutHtml?.trim()?.replace(/([\r\n]+)([A-Z])/g, '<br />$2')
        .replace(regex, '<br />');
};

export const displayItem = (loading: boolean, emptyText: string, text?: string | number): string | number => {
    if (loading) return '';
    if (!text) {
        return emptyText;
    }
    return text;
};

export const generateColor = (color: string, basic: number = 100) => {
    const num = parseInt(color.replace('#', ''), 16) - basic;

    return `#${num.toString(16)}`;
};

export const getImageType = (url: string) => {
    return url ? url.substring(url.lastIndexOf('.') + 1).toLowerCase() : null;
};

export const removeSpecialCharsFromStartAndEnd = (str: string = '') => str.replace(/^[^a-zA-Z0-9]+|[^a-zA-Z0-9]+$/g, '');

export const getElementWithTargetRole = (params: { access?: Record<PermissionModuleKeys, boolean> | null, element: React.JSX.Element, targetRoles?: PermissionModuleKeys[] }): React.JSX.Element | null => {
    const { access, targetRoles, element } = params;
    if (!access) {
        return null;
    }
    if (!targetRoles || targetRoles.length === 0) {
        return element;
    }
    let ret = <AccessDeniedPage />;
    for (let i = 0; i < targetRoles?.length; i++) {
        if (access[targetRoles[i]]) {
            ret = element;
            break;
        }
    }
    return ret;
};

export const convertLinksToAnchorTags = (text: string): string => {
    if (!text) {
        return text;
    }
    // 正则表达式以匹配超链接
    const linkRegex = /(?:https?|ftp):\/\/[^\s/$.?#].[^\s]*/gi;

    // 使用replace方法将匹配的超链接替换为包含<a>标签的超链接
    const newText = text.replace(linkRegex, (match) => {
        return `<a href="${match}" target="_blank">${match}</a>`;
    });

    return newText;
};

export const transAddressDataToServiceOptions = (addressList: Array<PracitceAddressForm> = []): Array<{ value: number, label: string, isDefault: boolean }> => {
    return (addressList || []).map((e) => {
        const { id, practiceAddress, practiceCity, practiceState, practiceZip, isDefault } = e;
        return {
            value: id as number,
            label: `${practiceAddress}, ${practiceCity}, ${practiceState} ${practiceZip}`,
            isDefault,
        };
    });
};

export const getStateDisplayName = (stateCode?: string, stateList?: SettingEnum[]): string | undefined => {
    const state = stateList?.find((e) => e.dictKey?.toLowerCase() === stateCode?.toLowerCase());
    return state?.content || stateCode;
};

export const getLocalSEOInfoFromServerData = (serverData?: Record<string, EditLocalSEOInfoServer>): LocalSEOInfo[] => {
    const serverSeoInfoArray: LocalSEOInfo[] = [];
    if (serverData) {
        Object.keys(serverData).forEach((state) => {
            const data = serverData[state];
            let landmarks;
            if (data?.landmark) {
                try {
                    landmarks = JSON.parse(data.landmark);
                } catch (e) {
                    console.error(e);
                }
            }
            let cities;
            if (data?.city) {
                try {
                    cities = JSON.parse(data.city);
                } catch (e) {
                    console.error(e);
                }
            }
            let counties;
            if (data?.country) {
                try {
                    counties = JSON.parse(data.country);
                } catch (e) {
                    console.error(e);
                }
            }
            let zip;
            if (data?.zip) {
                try {
                    zip = JSON.parse(data.zip);
                } catch (e) {
                    console.error(e);
                }
            }
            serverSeoInfoArray.push({
                state,
                landmarks,
                cities,
                counties,
                zip,
            });
        });
    }
    return serverSeoInfoArray;
};

export const isSameProspect = (prospect1: Partial<Prospect>, prospect2: Partial<Prospect>) => {
    return prospect1.channelId === prospect2.channelId && prospect1.firstName === prospect2.firstName && prospect1.lastName === prospect2.lastName && prospect1.tel === prospect2.tel;
};

export const urlSearchParamsReplacement = (url: string, key: string, value: string) => {
    let newLink = url;
    if (url && key) {
        const urlObj = new URL(url);

        const { search, origin, pathname } = urlObj;
        const searchParams = new URLSearchParams(search);

        searchParams.set(key, value || '');

        newLink = `${origin}${pathname}?${searchParams.toString()}`;
    }

    return newLink;
};

export const maskEmail = (email: string, repeat?: number) => {
    if (!email.includes('@')) {
        return email;
    }

    const parts = email.split('@');
    const username = parts[0];
    const maskedUsername = username.length > 2 ?
        username.charAt(0) + '*'.repeat(repeat || username.length - 2) + username.charAt(username.length - 1) :
        username;

    return `${maskedUsername}@${parts[1]}`;
};

export const sanitizeHtml = (html: string) => {
    const temp = document.createElement('div');
    // 将 HTML 字符串作为文本内容插入到 div 元素中
    temp.textContent = html;
    // 返回过滤后的 HTML 内容
    return temp.innerHTML;
};

export const decodeHtmlEntities = (htmlString: string) => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = htmlString;
    return textarea.value;
};

export const loadSalesPresentationUrlInIframe = (url?: string) => {
    if (!url) {
        return;
    }

    let newUrl = url;

    // 检查 URL 是否来自 Google Slides
    if (url.startsWith('https://docs.google.com/presentation/')) {
        // 查看 URL 是否包含 '/pub'
        if (url.includes('/pub')) {
            // 将 '/pub' 替换成 '/embed'
            newUrl = url.replace('/pub', '/embed');
        }

        if (url.includes('/edit')) {
            // 将 '/pub' 替换成 '/embed'
            newUrl = url.replace('/edit', '/embed');
        }
    }

    return newUrl;
};

export const generateUrlSearch = (params: Record<string, any>) => {
    const searchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(params)) {
        if (value !== undefined && value !== null && value !== '') {
            searchParams.append(key, value);
        }
    }

    return searchParams.toString();
};

//key may dickey or content, ignore case
export const findTargetMetaFromListByKey = (key?: string, list?: ServerMeta[]): ServerMeta | undefined => {
    if (!key || typeof key !== 'string') {
        return undefined;
    }
    return list?.find((item) => {
        return item.content.toLowerCase() === key.toLowerCase() || item.dictKey.toLowerCase() === key.toLowerCase();
    });
};

export const getDayKey = (val: Dayjs) => {
    return val.format('YYYY-MM-DD');
};

export const removeHMSInUTCTime = (utcString: string) => {
    if (!utcString) {
        return undefined;
    }

    return utcString.split('T')[0];
};

export const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = Math.random() * 16 | 0; const
            v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

export const getAiUserId = () => {
    return `ai-${getUserId()}`;
};

export const getAiApiData = (result: any) => {
    return result?.data?.data as any;
};

export const isAiApiSuccess = (result: any) => {
    return !result.error && result?.data?.message === 'Success';
};

export const getHelloKlarityHost = (): string => {
    let host = 'https://www.helloklarity.com';
    if (isTestEnv()) {
        host = 'https://test.d2ysjydukc4efk.amplifyapp.com';
    }
    if (isDevEnv()) {
        host = 'https://dev.d2ysjydukc4efk.amplifyapp.com';
    }
    return host;
};

export const transTimeStringToNumber = (time: string = '') => {
    const [hour, minute] = time.split(':').map(Number);

    return hour * 60 + minute;
};

export const getTheClosestTimeObj = (time: string, times: ITimePair[]): ITimePair | null => {
    const timeValue = transTimeStringToNumber(time);

    let closest = null;
    let minDiff = Infinity;

    times.forEach((t) => {
        const startValue = transTimeStringToNumber(t.start);
        const diff = Math.abs(startValue - timeValue);

        // startValue > timeBValue && startValue - timeBValue < minDiff
        if (startValue > timeValue && diff < minDiff && (time !== t.start)) {
            minDiff = diff;
            closest = t;
        }
    });

    return closest;
};

export const isTimeOverlap = (periodA: ITimePair, periodB: ITimePair) => {
    const startA = transTimeStringToNumber(periodA.start);
    const endA = transTimeStringToNumber(periodA.end);
    const startB = transTimeStringToNumber(periodB.start);
    const endB = transTimeStringToNumber(periodB.end);

    return (startA < endB && endA > startB);
};

export const get2Decimal = (input: any) => {
    // 确保输入是数字类型
    const num = parseFloat(input);

    // 如果解析失败，返回 'NaN'
    if (Number.isNaN(num)) {
        return 0;
    }

    // 使用 toFixed 保留两位小数
    return num.toFixed(2);
};

export const shouldShowAdvertisingIcon = (currentPlan: TSubscriptionItemFromServer) => {
    const isOPS = isOpPortal();
    if (isOPS || !currentPlan) {
        return false;
    }

    const showAdvertisingIconLS = localStorage.getItem(SHOW_KLARITY_ADVERTISING_ICON);
    return (showAdvertisingIconLS === '1' && !dontShowAdvertisingPaht.includes(window?.location?.pathname)) && (currentPlan.type === TSubscriptionType.KLARITY_STARTER || currentPlan.type === TSubscriptionType.KLARITY);
};

export const checkChannelIsLocked = (channel: Channel, myPlanLevel: number, isOperation?: boolean) => {
    return isOperation ? false : (myPlanLevel < channel.klarityPlanLevel);
};

export const isHeadwayWebsite = (url?: string) => {
    if (!url) {
        return false;
    }
    try {
        const urlObj = new URL(url);
        const { host } = urlObj;

        if (host.replace(/www\./g, '') === 'headway.co') {
            return true;
        }
    } catch (e) {
        console.error(e);
    }

    return false;
};

export const getPlainTextLength = (content: string = '') => {
    const plainText = content.replace(/<[^>]*>/g, ''); // 移除 HTML 标签
    return plainText.length;
};
